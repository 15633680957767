import React from "react"
import Layout from "../components/layout";
import tw, { styled } from 'twin.macro';
const StyledSection = styled.section`
    ${tw`grid items-center relative pt-24`};
    .main-container {
      ${tw`flex flex-col justify-center container mx-auto md:(px-8 py-12) py-8 px-5`};
      p {
        ${tw`text-dark pb-4`}
      }

      .wrapper {
          h3 {
            ${tw`text-2xl font-bold pt-4`}
          }
          p {
            ${tw`py-4`}
          }
          ul {
            ${tw`flex flex-col gap-4 list-disc pl-4`}
          }
      }
    }
`;
const TermsOfUse = () => {
    return (
        <Layout bgWhite={true}>
            <StyledSection>
                <div className="main-container">
                    <p>
                        These terms and conditions apply to the use of this website and the Housefull
                        events registration. By creating an account in Housefull events platform, you accept these terms and conditions and
                        you agree to be bound by these terms and conditions and Housefull’ Privacy Policy. If you do not accept these
                        terms and conditions and Housefull’ Privacy Policy, do not continue to use the Housefull registration platform.
                    </p>
                    <div className="wrapper">
                        <h3>1. Terminology</h3>
                        <p>In these terms and conditions:</p>
                        <ul>
                            <li>The expressions we, us, our, and Housefull Events refer to Housefull Events &
                                Hospitality Management
                                Services, Incorporated with registered office in No:78, 39, SMV Kovil Street,
                                Triplicane, Chennai, Tamil Nadu - 600005.
                            </li>
                            <li>
                                Housefull Events Platform means the web based software platform together
                                with associated plugins,
                                application program interfaces (API’s), sub-modules, services and support
                                infrastructure created and
                                operated by Housefull Events that enables the creation of Event Listings,
                                acceptance of registrations, and
                                collection, storage and access of data relating to events. The Housefull Events
                                Websites are part of the
                                Housefull Events Platform.
                            </li>
                            <li>
                                Event Organizer means a person, corporation or association that uses the
                                Housefull Events Platform to accept registrations for an event.
                            </li>
                            <li>
                                Event Listing means a mini website created by an Event Organizer using the
                                Housefull Events Platform which provides information about an event and collects
                                and stores registration information.
                            </li>
                            <li>
                                Listing Content means content an Event Organizer includes in an Event Listing.
                            </li>
                            <li>
                                Services means any services provided by Housefull Events to you in relation to
                                an event, including access to
                                the Housefull Events Platform, data capture, and the services as described in
                                paragraph 3.
                            </li>
                            <li>
                                Sensitive information is data that must be protected from unauthorized access
                                to safeguard the privacy
                                or security of an individual or organization
                            </li>
                            <li>
                                User means a person who registers for an event through the Housefull Events
                                Platform, or who provides
                                data in response to a Vendor Data Capture Question.
                            </li>
                            <li>
                                Vendor, you and your refer to an exhibitor at, seller of products or services at,
                                or sponsor of an event for which the Housefull Events Platform has been used to
                                register.
                            </li>
                            <li>
                                Vendor Data Capture Question means a question asked of a person attending or
                                being a part of an event, using the Housefull Events Platform, in order to obtain
                                User data relevant to a Vendor.
                            </li>
                            <li>
                                Your Event means an event for which you are registered as a Vendor on the
                                Housefull Events Platform.
                            </li>
                            <li>
                                Your Event Organizer means an Event Organizer of one of Your Events.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>2. AMENDMENTS TO TERMS AND CONDITIONS</h3>
                        <p>
                            We reserve the right to amend these terms and conditions from time to time by
                            either posting the changes on the
                            Housefull Events Website (<a href="www.housefullevents.in">www.housefullevents.in</a> ) or by giving you notice in
                            accordance with paragraph 20. It is
                            your responsibility to check this website for changes before using the Housefull
                            Events Platform in relation to a new
                            event. The amendments will not apply to an event if the Event Listing was created
                            before notice of the amendment is
                            given. Your use of the Housefull Events Platform following notice will indicate that
                            you agree to be bound by the
                            terms and conditions as amended.
                        </p>
                    </div>
                    <div className="wrapper">
                        <h3>3. PROVISION OF SERVICES</h3>
                        <p>
                            Housefull Events may agree to provide the following Services to Vendors:
                        </p>
                        <ul>
                            <li>
                                the ability to use the Housefull Events Platform at Your or Their Events to ask Vendor Data Capture
                                Questions and store the resulting data; and
                            </li>
                            <li>
                                leasing hardware and other devices to facilitate access control and registration at events, and payment
                                processing systems such as mobile point of sale technology.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            4. FEES AND PAYMENTS
                        </h3>
                        <p>
                            Housefull Events & Hospitality Management charges yearly ticked fees for
                            listing the details in web application. The ticket fees will be at discretion of
                            Housefull Events & Hospitality Management and will be informed during
                            the process of listing with organiser.
                        </p>
                        <p>
                            Any payments received directly by Housefull Events & Hospitality
                            Management from users and payable to organisers will be paid after
                            deducting any statutory deductions applicable under Indian Tax Laws.
                        </p>
                    </div>
                    <div className="wrapper">
                        <h3>
                            5. REGISTRATION
                        </h3>
                        <ul>
                            <li>
                                To use the Housefull Events Platform to obtain Services, you must register as a
                                Vendor. You must provide
                                accurate complete and current information as required by Housefull Events’
                                registration form (Registration
                                Data) and update your Registration Data at all times.
                            </li>
                            <li>
                                If you are an individual, you must be at least eighteen years old to register as a
                                Vendor.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            6. ACCOUNT PASSWORD AND SECURITY
                        </h3>
                        <p>
                            As part of the registration process, you will create a password and account. You
                            must maintain the confidentiality of
                            the password and security of the account and are fully responsible for all use
                            made of your account unless you can
                            establish to our reasonable satisfaction that the use was unauthorized and did not
                            result from a breach of these
                            Terms. You agree to:
                        </p>
                        <ul>
                            <li>
                                immediately notify Housefull Events of any unauthorized use of your password
                                or account or any other
                                breach of security; and
                            </li>
                            <li>
                                ensure that you exit from your account at the end of each session.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            7. USE OF THE Housefull Events PLATFORM
                        </h3>
                        <p>
                            Housefull Events grants you a non-exclusive, non-transferable right (without the
                            right to sub-license) to access and
                            use the Housefull Events Platform solely for the purposes of collecting, storing
                            and accessing data concerning to Your
                            Events.
                            You must not:
                        </p>
                        <ul>
                            <li>
                                remove, alter or hide any of the content on the Event’s Website or Housefull
                                Events Website or any Listing
                                Content;
                            </li>
                            <li>
                                attempt to hack, expose vulnerabilities or otherwise interfere with the
                                operation of the Housefull
                                Events Platform or an Event Organizer website, or any data stored on it; or
                            </li>
                            <li>
                                attempt to monitor API calls, network traffic or other data being transmitted to
                                and from the Housefull
                                Events Platform.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            8. YOUR DATA
                        </h3>
                        <p>
                            You consent to Housefull Events retaining and using any data that you provide to
                            Housefull Events, or which is
                            obtained by Housefull Events in connection with Your Events from third parties, in
                            accordance with Housefull Events’
                            Privacy Policy. The purposes for which that data may be retained and used by
                            Housefull Events or its related entities
                            include marketing in accordance with Housefull Events’ Privacy Policy, unless this
                            is prohibited by applicable
                            legislation
                        </p>
                    </div>
                    <div className="wrapper">
                        <h3>
                            9. CODE OF CONDUCT
                        </h3>
                        <p>
                            You agree to adhere to the following code of conduct in relation to Your Events:
                            You must comply with all applicable legislation, including all applicable consumer
                            protection laws in the venue state
                            where your event is located.
                            You must not use the Housefull Events Platform:
                        </p>
                        <ul>
                            <li>
                                in a manner that is misleading or deceptive;
                            </li>
                            <li>
                                to infringe the intellectual property or other legal rights of any person or
                                corporation;
                            </li>
                            <li>
                                To distribute or publish any material (including a Vendor Data Capture
                                Question) which is obscene,
                                defamatory, threatening or abusive, or to provide links to another website which
                                contains such material;
                            </li>
                            <li>
                                for any purpose or activity, or in any manner which is unlawful, or to promote
                                any such activity;
                            </li>
                            <li>
                                To access, store, distribute or use personal information in breach of any
                                applicable legislation, Housefull
                                Events’ Privacy Policy, Your Event Organizer’s Privacy Policy or your Privacy Policy
                                if you are required to have
                                one by the privacy laws governing your particular industry or of the venue state in
                                which your event is held;
                            </li>
                            <li>
                                Without Housefull Events’ prior written agreement, to collect from Users any
                                Sensitive Information.
                            </li>
                            <li>
                                to interfere with or disrupt Housefull Events or its business, or those of users of
                                the Housefull
                                Events Platform, or their computers, software or hardware, including by the
                                propagation of computer worms
                                and viruses; or
                            </li>
                            <li>
                                to access any computer or data without authorization.
                                Housefull Events may remove Vendor Data Capture Questions and associated
                                data that it considers do not comply
                                with these Terms and Conditions without prior notice to you.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            10. COPYRIGHT
                        </h3>
                        <p>
                            Copyright in the Housefull Events Platform (including text, graphics, logos, icons,
                            sound recordings and software) is
                            owned or licensed by Housefull Events. Other than for the purposes of, and
                            subject to the conditions prescribed
                            under, The Copyright Act 1957 (as amended by the Copyright Amendment Act
                            2012) and similar legislation which applies in your
                            location, and except as expressly authorized by these Terms and Conditions or by
                            Housefull Events in writing, you
                            may not in any form or by any means:
                        </p>
                        <ul>
                            <li>
                                adapt, reproduce, store, distribute, print, display, perform, publish or create
                                new works from any part of the
                                Housefull Events Platform; or
                            </li>
                            <li>
                                reverse engineer, decompile, or otherwise try to access the source code, user
                                flows or application
                                architecture of the Housefull Events Platform.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            11. WARRANTIES AND LIMITATION OF LIABILITY.
                        </h3>
                        <p>
                            Housefull Events does not give any express warranties in relation to the Housefull
                            Events Platform or the Services,
                            including warranties that:
                        </p>
                        <ul>
                            <li>
                                you, Event Organizers or Users will have continuous access to, or usage of the
                                Housefull Events Platform or
                                the Services; or
                            </li>
                            <li>
                                data stored on Housefull Events servers will not be lost or corrupted;
                            </li>
                            <li>
                                It will be possible to restore your data from Housefull Events’ back up media; or
                            </li>
                            <li>
                                it will be able to prevent unauthorized persons obtaining access to the Housefull
                                Events Platform or your
                                Vendor Data Capture Questions and associated data.
                                Any condition or warranty which would otherwise be implied into these Terms
                                and Conditions is excluded, unless it
                                would be unlawful to do so.
                                Housefull Events’ liability to you in relation to the Housefull Events Platform, the
                                Housefull Events Website, the
                                Services or these terms and Conditions on any legal basis is limited, to the extent
                                it is lawful to do so, to one or more
                                of the following:
                            </li>
                            <li>
                                the supplying of the Services again; or
                            </li>
                            <li>
                                the payment of the costs of having the Services supplied again.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            12. INDEMNITY
                        </h3>
                        <p>
                            You agree to indemnify Housefull Events from any claims, damages, costs,
                            expenses and liabilities (including legal
                            costs on an indemnity basis) arising out of or in connection with:
                        </p>
                        <ul>
                            <li>
                                your breach of these Terms and Conditions;
                            </li>
                            <li>
                                any unauthorized disclosure or use of information obtained through use of the
                                Housefull Events Platform or
                                an Event Listing by you, or by use of your account by any person; or
                            </li>
                            <li>
                                any contract between you and an Event Organizer or a User;
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            13. SUSPENSION OF SERVICES AND ACCESS
                        </h3>
                        <p>
                            Housefull Events may from time to time without prior notice to you suspend the
                            Services, deny you access to the
                            Housefull Events Platform, or delete your Vendor Data and associated data if:
                        </p>
                        <ul>
                            <li>
                                It is necessary to do so due to any technical failure of, or for the upgrading or
                                preventative or remedial
                                maintenance of Housefull Events’ computer systems and software;
                            </li>
                            <li>
                                In Housefull Events’ reasonable opinion, and in its sole discretion, your use of
                                the Housefull Events Platform
                                or the Services, or one of your Vendor Data Capture Questions is unlawful;
                            </li>
                            <li>
                                Without Housefull Events’ prior written consent you collect, or engage in
                                conduct that is likely to result in the
                                collection of Sensitive Information from Users;
                            </li>
                            <li>
                                In Housefull Events’ reasonable opinion, and in its sole discretion, it is required
                                by law to do so; or
                            </li>
                            <li>
                                In Housefull Events’ reasonable opinion, and in its sole discretion, your use of
                                the Services or the Housefull
                                Events Platform adversely affects other Vendors or Users, or the integrity of
                                Housefull Events’ computer
                                network.
                                Housefull Events may, on giving you two business days’ notice, suspend your right
                                to use the Housefull
                                Events Platform and the Services to create new Event Listings if you breach these
                                Terms and Conditions. Access will
                                not be reinstated until the breach is remedied if capable of being remedied, and
                                any loss, damage, costs and expenses
                                incurred by Housefull Events in relation to the breach are compensated in full.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>
                            14. TERMINATION
                        </h3>
                        <p>
                            Housefull Events, in its sole discretion, may terminate its agreement with you and
                            your right to use the Housefull
                            Events Platform and the Services any time by giving you at least two weeks’
                            notice. On termination or expiration of
                            Housefull Events’ agreement with you it may deactivate, delete or bar your access
                            to your account. Housefull
                            Events may also delete all Vendor Data Capture Questions, associated data and
                            files related to your account, unless
                            you have an Event Listing for an event that has not yet taken place (Future Event
                            Listing). If you have any Future Event
                            Listings, Housefull Events will not delete them until seven business days after the
                            event takes place, or until Housefull
                            Events is satisfied that the event will not take place on the date stated in the
                            Event Listing on the day before notice is
                            given.
                        </p>
                    </div>
                    <div className="wrapper">
                        <h3>
                            15. SUB-CONTRACTS
                        </h3>
                        <p>
                            Housefull Events may sub-contract the performance of its obligations under these
                            Terms and Conditions without
                            obtaining your consent.
                        </p>
                    </div>
                    <div className="wrapper">
                        <h3>
                            16. NOTICE
                        </h3>
                        <p>
                            Any notice under these Terms and Condition shall be in writing. We may deliver
                            notices to you by email to your email
                            address as provided by you to us from time to time. Notice will be treated as
                            given:
                        </p>
                        <ul>
                            <li>
                                in the case of postal delivery, on the date of delivery recorded by the postal
                                authority;
                            </li>
                            <li>
                                in the case of email, on receipt by the sender of notification that the email has
                                been received by the
                                recipient’s email server,
                                But if the delivery or receipt is not on a business day or is after 5:00pm on a
                                business day in the place where it is
                                received, the notice shall be considered received at 9:00am on the next business
                                day.
                                It is your responsibility to notify us of any changes to your contact details.
                                Any notice from you to us shall be sent in writing to our email address (
                                <a href="mailto:planning@housefullevents.in">planning@housefullevents.in</a> ), or another
                                email address notified by us to you.
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper">
                        <h3>17. GOVERNING LAW</h3>
                        <p>
                            This Agreement (and the validity and enforceability of this Agreement) is
                            governed by the Indian Law, without
                            reference to conflicts of law. You submit to the non-exclusive jurisdiction of the
                            courts exercising jurisdiction in the
                            Country of India.
                        </p>
                    </div>
                </div>
            </StyledSection>
        </Layout >
    )
};

export default TermsOfUse
